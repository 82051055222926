.wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: var(--color-white);
  display: grid;
  justify-content: space-between;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  margin-top: auto;
  margin-bottom: 0;
}
.long {
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
  background: var(--color-main);
  transition: 0.2s;
}
.item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 15px 0 10px;
  overflow: hidden;
}
.item svg {
  height: 20px;
  max-width: 20px;
  width: 20px;
  max-height: 20px;
  overflow: hidden;
}
.item p {
  font-family: var(--font-Cambay);
  font-weight: 700;
  font-size: 12px;
  transition: 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}
.item use {
  transition: 0.2s;
}
.image {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.2s;
}
.image-active {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px var(--color-main);
}