@import 'modern-normalize/modern-normalize.css';

html {
}
* {
  -webkit-tap-highlight-color: transparent;
}
body {
  overflow-x: hidden;
}
main, .main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
ul, li, ol {
  /*margin: 0;*/
  /*padding: 0;*/
}
p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
button {
  border: none;
  padding: 0;
  cursor: pointer;
  background: none;
  text-align: start;
}
a {
  transition: opacity 0.2s;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  text-decoration-color: currentColor;
}
input {
  outline: none;
  border: none;
}
/*events*/
a:hover {
  text-decoration: underline;
  opacity: 0.7;
}
/*media*/
@media (max-width: 768px) {
  a:hover {
    text-decoration: none;
    opacity: 1;
  }
  a:active {
    opacity: 0.7;
    text-decoration: underline;
  }
}
.gm-style-moc {
  transition: 0.2s !important;
}
.gm-style-mot {
  font-weight: 500;
  font-family: var(--font-Montserrat);
  font-size: 17px;
}