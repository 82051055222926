:root {
  /* Breakpoints */
  --site-breakpoint-mobile: 768px;

  --site-max-width: 1402px;
  --site-max-width-wrapper: 1920px;
  --site-width: 92vw;

  --site-horizontal-padding: 4vw;

  /* Colors */
  --color-main: #E89732;
  --color-grey: #7d7d7d;
  --color-grey-border: #d5d5d5;
  --color-checkbox-btn: #bebebe;
  --color-footer: #21323c;
  --color-white: #FFFFFF;
  --color-error: #ff0a0a;
  --color-card: #4e4439;
  --color-form: #f4f4f4a8;
  --modal-fon: rgba(0, 0, 0, 0.4);
  --color-selector-item: rgba(0, 0, 0, 0.3);

  /* Bg */
  --background-color-input: #F1F1F166;
  --background-color-border-input: #0000004D;

  /* Fonts */
  --font-Raleway: Raleway, sans-serif;
  --font-Campton: Campton, sans-serif;
  --font-Cambay: Cambay, sans-serif;
  --font-Glitten: Glitten, sans-serif;
  --font-Montserrat: Montserrat, sans-serif;

  /* Misc */
  --border-radius: 5px;

  /*banner-padding*/
  --search-form-padding-horizontal: 20px;
  --search-form-padding-vertical: 15px;
  --search-form-width-button: 115px;

  --padding-book: clamp(12px, 0.78vw, 15px);
}
@media (max-width: 768px) {
  :root {
    --search-form-padding-horizontal: 15px;
    --search-form-padding-vertical: 10px;
  }
}