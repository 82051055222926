.substrate-fon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-fon);
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
}
.substrate-fon-active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 769px) {
  .right-button-styles-form-options {
    padding-right: calc((var(--search-form-padding-horizontal) * 2) + var(--search-form-width-button)) !important;
  }
}
.block-selectors-search-options {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}
.search-options-long {
  position: relative;
  width: 1px;
  min-width: 1px;
  height: calc(90% - (var(--search-form-padding-vertical) * 2));
  background: var(--color-grey-border);
  transition: 0.2s;
}
.search-options-long-none {
  display: none;
}
.block-selectors-search-options:hover .search-options-long {
  opacity: 0;
}
.submit-button-search-options {
  position: relative;
  padding: 0 var(--search-form-padding-horizontal);
  align-self: center;
}


.location-property-drop-search {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  width: max-content;
}
[dir="rtl"] .location-property-drop-search {
  left: auto;
  right: 0;
}
.calendar-property-drop-search {
  position: absolute;
  top: calc(100% + 10px);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  width: max-content;
  transform: translateX(-50%);
}
[dir="rtl"] .calendar-property-drop-search {
  transform: translateX(50%);
}
.guests-property-drop-search {
  position: absolute;
  top: calc(100% + 10px);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  width: max-content;
}
.category-car-drop-search {
  position: absolute;
  top: calc(100% + 10px);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  width: max-content;
}
.calendar-car-drop-search {
  position: absolute;
  top: calc(100% + 10px);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  width: max-content;
  transform: translateX(-50%);
}
[dir="rtl"] .calendar-car-drop-search {
  transform: translateX(50%);
}
.city-tour-drop-search {
  position: absolute;
  top: calc(100% + 10px);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
  width: max-content;
}
@media (max-width: 900px) {
  .calendar-property-drop-search {
    left: 0 !important;
    transform: translateX(0);
  }
  [dir="rtl"] .calendar-property-drop-search {
    right: 0 !important;
    left: auto !important;
    transform: translateX(0);
  }
  .calendar-car-drop-search {
    left: auto !important;
    transform: translateX(0);
    right: 0;
  }
  [dir="rtl"] .calendar-car-drop-search {
    left: 0 !important;
    right: auto !important;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .search-options-long {
    display: none;
  }
  .block-selectors-search-options {
    position: relative;
    display: flex;
    padding-right: 0;
    align-items: start;
    flex-direction: column;
    justify-content: start;
    flex-grow: initial;
    gap: 5px;
    overflow: visible;
  }
  .location-property-drop-search {
    top: calc(100% + 5px);
    width: 100%;
  }
  .calendar-property-drop-search {
    left: 0 !important;
    top: calc(100% + 5px);
    width: 100%;
    transform: initial;
  }
  .guests-property-drop-search {
    right: auto !important;
    left: 0 !important;
    top: calc(100% + 5px);
    width: 100%;
  }
  .category-car-drop-search {
    left: 0 !important;
    right: 0 !important;
    top: calc(100% + 5px);
    width: 100%;
  }
  .calendar-car-drop-search {
    left: 0;
    right: auto !important;
    top: calc(100% + 5px);
    width: 100%;
  }
  .city-tour-drop-search {
    left: 0 !important;
    top: calc(100% + 5px);
    width: 100%;
  }
}
/*styles*/
.areas-category-block {
  display: flex;
  width: max-content;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: calc(var(--search-form-padding-horizontal) / 2);
  padding: var(--search-form-padding-vertical) var(--search-form-padding-horizontal);
}
.areas-category-item {
  position: relative;
  width: clamp(120px, 7.81vw, 150px);
  min-width: clamp(120px, 7.81vw, 150px);
  max-width: clamp(120px, 7.81vw, 150px);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: transparent;
}
.areas-category-item:before {
  transition: 0.2s;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + var(--search-form-padding-horizontal) / 2);
  height: calc(100% + var(--search-form-padding-vertical));
  background: transparent;
  border-radius: var(--border-radius);
}
.areas-category-item:active {
  transform: scale(0.95);
}
.areas-category-item:hover:before {
  background: rgba(0, 0, 0, 0.10);
}
.areas-category-item-image {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 1px solid #DDDDDD;
  width: 100%;
}
.areas-category-item-name {
  position: absolute;
  text-align: center;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border-radius: var(--border-radius);
  padding: 8px 5px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.6);
  font-family: var(--font-Raleway);
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 600;
  color: #363636;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
}
.areas-category-item-image:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  box-shadow: 0 0 100px 40px rgba(0, 0, 0, 0.69);
  z-index: 1;
  overflow: hidden;
}
.areas-category-check-block {
  position: absolute;
  right: 10px;
  top: 10px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.areas-category-check-block span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 14px;
}
.areas-category-item-no-options {}
.guest-block {
  display: flex;
  flex-direction: column;
  gap: calc(var(--search-form-padding-vertical) * 0.8);
  padding: var(--search-form-padding-vertical) var(--search-form-padding-horizontal);
}
.dates-block {
  padding: var(--search-form-padding-vertical) var(--search-form-padding-horizontal);
}
@media (max-width: 768px) {
  .areas-category-block {
    width: 100%;
  }
  .areas-category-item {
    width: 130px;
    min-width: 130px;
    max-width: 130px;
    transition: 0.2s;
  }
  .areas-category-item:hover:before {
    background: transparent;
  }
  .areas-category-item:active:before {
    background: rgba(0, 0, 0, 0.10);
  }
}