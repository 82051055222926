.container {
  flex-grow: 1;
  display: flex;
  margin: 0 auto;
  align-items: center;
  align-self: center;
}
.item {
  position: absolute;
  opacity: 0;
  background: var(--color-main);
  border-radius: 50%;
}
