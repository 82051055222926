.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
  margin-left: 0.833em;
}

.rdrDateDisplayItem input{
  text-align: inherit
}

.rdrDateDisplayItem input:disabled{
  cursor: default;
}

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers{
  display: none;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.rdrMonthPicker{}

.rdrYearPicker{}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
  display: flex;
  gap: clamp(20px, 1.5625vw, 30px);
}

.rdrMonthsVertical {
  flex-direction: column;
}
.booking-custom .rdrMonthsVertical {
  /*padding: 0 4%;*/
}

.rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.rdrMonth {
  width: clamp(300px, 18.23vw, 350px);
}
.react-date-range-custom-select-form .rdrMonth {
  width: clamp(280px, 16.667vw, 320px);
}

.rdrWeekDays{
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.rdrDateDisplayWrapper{
  display: none;
}

.rdrMonthName{}

.rdrInfiniteMonths{
  overflow: auto;
  overflow-x: hidden;
}

.rdrDateRangeWrapper{
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  position: absolute;
  font-size: 1.6em;
  line-height: 1.6em;
  top: 0;
  right: .25em;
  color: #FF0000;
}

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}
.rdrDayNumber .price-custom {
  color: #7C7C7C;
}
.rdrDayNumber span{
  color: #313131;
}
.rdrDayNumber .check-in-out-day-custom {
  color: rgba(49, 49, 49, 0.40);
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.rdrStaticRange{
  font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
  display: flex;
}

.rdrInputRangeInput{}
@media (max-width: 768px) {
  .react-date-range-custom-select-form .rdrMonth {
    width: auto;
  }
}

/*[dir="rtl"] .react-date-range {*/
/*  direction: rtl; !* Меняет направление *!*/
/*}*/

/*[dir="rtl"] .react-date-range .rdr-calendar-wrapper {*/
/*  flex-direction: row-reverse; !* Инвертирует отображение календарей *!*/
/*}*/

/*[dir="rtl"] .react-date-range .rdr-month-and-year-wrapper {*/
/*  flex-direction: row-reverse; !* Переворачивает месяц и год *!*/
/*}*/

/*[dir="rtl"] .react-date-range .rdr-month {*/
/*  direction: rtl; !* Меняет текст внутри календаря *!*/
/*}*/