.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  opacity: 0;
}
.logo {
  width: clamp(100px, 7.8vw, 150px);
}
.strokeAnimation {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: transparent;
}
.logo1 {
  stroke: #081318;
  fill: #081318;
  stroke-width: 5;
  animation: draw 1s linear infinite, fillColorLogo1 1s linear infinite;
  animation-delay: 0.1s;
  stroke-dashoffset: 0;
}
.logo2 {
  stroke: #ff9622;
  fill: #ff9622;
  stroke-width: 5;
  animation: draw 1s linear infinite, fillColorLogo2 1s linear infinite;
  animation-delay: 0.1s;
  stroke-dashoffset: 0;
}
@keyframes draw {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fillColorLogo1 {
  0% {
    fill: #081318;
  }
  30% {
    fill: transparent;
  }
  70% {
    fill: transparent;
  }
  100% {
    fill: #081318;
  }
}
@keyframes fillColorLogo2 {
  0% {
    fill: #ff9622;
  }
  30% {
    fill: transparent;
  }
  70% {
    fill: transparent;
  }
  100% {
    fill: #ff9622;
  }
}