/*global*/
.wrapper {
  width: var(--site-width);
  max-width: var(--site-max-width);
  margin: 0 auto;
}
.standard-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(24px, 2.08vw, 40px);
}
@media (max-width: 1025px) {
  .standard-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .standard-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.error-message {
  margin: 5px auto;
  font-size: 12px;
  text-align: center;
  color: var(--color-error);
}
/*form*/
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 2px 0;
}
.form-error {
  margin: 10px auto;
  font-size: 12px;
  text-align: center;
  color: var(--color-error);
}
.input-form {
  background: var(--background-color-input);
  border-bottom: 1px solid var(--background-color-border-input);
  border-radius: var(--border-radius);
  padding: 14px 12px;
  font-family: var(--font-Montserrat);
  font-weight: 400;
  color: var(--color-grey);
  /*font-size: clamp(14px, 0.9375vw, 18px);*/
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-form:disabled {
  opacity: 0.7;
}
.input-form::placeholder {
  color: var(--color-grey);
  font-weight: 300;
}
.input-title {
  font-family: var(--font-Montserrat);
  /*font-size: clamp(14px, 0.9375vw, 18px);*/
  font-size: 14px;
  font-weight: 400;
  color: var(--color-grey);
}
.form-title {
  font-size: 30px;
  font-weight: 400;
  color: #4e4439;
}
.error-validate {
  color: var(--color-error);
  margin-top: 5px;
  margin-left: 12px;
  font-size: 10px;
}
/*back-loading*/
.loading-fon {
  overflow: hidden;
}
.loading-fon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #e7e7e7;
  border-radius: var(--border-radius);
}
.loading-fon:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 70%;
  height: 150%;
  background: linear-gradient(90deg, rgba(252,208,152,0) 0%, rgba(252,208,152,0) 20%, rgba(255, 255, 255, 0.62) 50%, rgba(252,208,152,0) 80%, rgba(252,208,152,0) 100%);
  transform: translateY(-50%) rotate(20deg);
  animation: glare_animate 1.5s ease-in-out infinite;
}
@keyframes glare_animate {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}