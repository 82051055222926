.showBlock {
  position: absolute;
  transform: translate(-50%, -50%);
  animation-duration: 2s;
  z-index: -1;
}
.showBlockOn {
  animation-name: showAnimate;
}
@keyframes showAnimate {
  0%{
    width: 50px;
    height: 50px;
    box-shadow: 0 0 10px 10px #e8973259;
  }
  90%{
    width: 300px;
    height: 300px;
    box-shadow: 0 0 10px 10px #e8973259;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 10px 10px #e8973259;
  }
}